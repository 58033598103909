import { bffClient, IClient } from 'shared/client'

import { ICountryOption } from '../model/country.model'

export class CountryApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getCountryOptions() {
    return this.client.get<ICountryOption[]>('/data/v2/country-list')
  }
}

export const countryApi = new CountryApi(bffClient)
