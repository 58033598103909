import { KeyPrefix, Namespace, TFunction } from 'i18next'
import { useTranslation, UseTranslationOptions } from 'react-i18next'

export type UseI18nResponse<N extends Namespace, TKPrefix = undefined> = [
  TFunction<N, TKPrefix>,
]

export function useI18n<
  N extends Namespace = 'common',
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  ns: N,
  options?: UseTranslationOptions<TKPrefix>,
): UseI18nResponse<N, TKPrefix> {
  const { t } = useTranslation(ns, options)
  return [t]
}
