import React from 'react'

import {
  FrankieTableHeaderCell,
  FrankieTableHeaderCellSortValues,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { userQa } from '../../../qa/user.qa'
import { USER_KEY } from '../../../user.key'
import { UserTableRowLayout } from '../user-table-row-layout/user-table-row-layout'

export enum UserSortByTypes {
  Name = 'name',
  LastLoginDate = 'lastLoginDate',
  CreatedDate = 'createdDate',
}

type Props = {
  sorts: Record<UserSortByTypes, FrankieTableHeaderCellSortValues>
  onChangeSort: (sortBy: UserSortByTypes) => () => void
  canSeeRole: boolean
}

export function UserTableRowHeader({ sorts, onChangeSort, canSeeRole }: Props) {
  const [t] = useI18n([USER_KEY])

  return (
    <div className="w-full text-xs font-medium text-tertiary-grey-500 leading-4 bg-tertiary-grey-50">
      <UserTableRowLayout
        nameCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(UserSortByTypes.Name)}
            sortValue={sorts[UserSortByTypes.Name]}
            testId={{ sort: userQa.userTableHeader_sortByName }}
          >
            {t('tableRowHeader.name')}
          </FrankieTableHeaderCell>
        }
        emailCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.email')}
          </FrankieTableHeaderCell>
        }
        isLockedCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.isLocked')}
          </FrankieTableHeaderCell>
        }
        roleCell={
          canSeeRole ? (
            <FrankieTableHeaderCell>
              {t('tableRowHeader.role')}
            </FrankieTableHeaderCell>
          ) : undefined
        }
        mfaCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.mfaStatus')}
          </FrankieTableHeaderCell>
        }
        createdDateCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(UserSortByTypes.CreatedDate)}
            sortValue={sorts[UserSortByTypes.CreatedDate]}
            testId={{ sort: userQa.userTableHeader_sortByCreatedDate }}
          >
            {t('tableRowHeader.createdDate')}
          </FrankieTableHeaderCell>
        }
        lastLoginDateCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(UserSortByTypes.LastLoginDate)}
            sortValue={sorts[UserSortByTypes.LastLoginDate]}
            testId={{ sort: userQa.userTableHeader_sortByLoggedDate }}
          >
            {t('tableRowHeader.lastLoginDate')}
          </FrankieTableHeaderCell>
        }
        actionsCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.actions')}
          </FrankieTableHeaderCell>
        }
      />
    </div>
  )
}
