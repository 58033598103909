import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IUser, UserId } from 'entities/user'

import { UserListQueryKey } from './user-list.query'
import { userManagementApi } from '../../api/user-management.api'

export const useChangeUserLockMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, locked }: { id: UserId; locked: boolean }) =>
      userManagementApi.changeUserLock(id, locked),
    onSuccess: ({ data: newUser }) => {
      const users = queryClient.getQueryData<IUser[]>(UserListQueryKey)
      if (users) {
        const newUsers = users.map<IUser>(user =>
          user.id === newUser.id ? newUser : user,
        )
        queryClient.setQueryData(UserListQueryKey, newUsers)
      }
    },
  })
}
