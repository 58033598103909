import { entities } from './entities'
import { features } from './features'
import { fwidgets } from './fwidgets'
import { pages } from './pages'
import { shared } from './shared'

export const defaultNs = 'common'
export const en = {
  ...pages,
  ...fwidgets,
  ...features,
  ...entities,
  [defaultNs]: {
    ...shared,
  },
}
