import countryListJson from './country.json'

/**
 * @example AND, USA, AUS, ARM
 */
export type CountryAlpha3Code = string

/**
 * Ordinary name in English
 * @example Andorra, United States of America
 */
export type CountryName = string

/**
 * @example US-AL, US-AK
 */
export type CountrySubdivisionCode = string

/**
 * Ordinary name in English
 * @example Alabama, Alaska
 */
export type CountrySubdivisionName = string

export interface ICountryOptionSubdivision {
  name: CountrySubdivisionName
  subdivisionCode: CountrySubdivisionCode
}

export interface ICountryOption {
  name: CountryName
  alpha3code: CountryAlpha3Code
  subdivision?: ICountryOptionSubdivision[]
}

export const countryList: ICountryOption[] =
  countryListJson.map<ICountryOption>(country => ({
    alpha3code: country.alpha3Code,
    name: country.name,
  }))
