import React, { useMemo, useState } from 'react'

import { FrankieLoader } from 'frankify/src'

import {
  RoleRemoveConfirm,
  useDeleteRoleMutation,
  useRoleListQuery,
} from 'features/role-management'
import {
  useUpdateUsersMutation,
  useUserListQuery,
} from 'features/user-management'

import { IRole, PermissionTypes, RoleId } from 'entities/role'
import { useHasPermission } from 'entities/session'
import { IUser } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Nullable } from 'shared/typescript'

import { transDataToApiBody } from './model/role-remove-from-users.model'
import {
  IReassignRoleInputs,
  ReassignRolesForm,
} from './reassign-roles-form/reassign-roles-form'
import { ROLES_TO_USERS_KEY } from '../../roles-to-users.key'

type Props = {
  roleId: RoleId
  onCancel: () => void
  onSuccess: () => void
}

export function RoleRemoveFromUsers({ roleId, onCancel, onSuccess }: Props) {
  const [t] = useI18n([ROLES_TO_USERS_KEY])
  const [isRoleReassigned, setIsRoleReassigned] = useState<boolean>(false)
  const [reassignRoleData, setReassignRoleData] =
    useState<Nullable<IReassignRoleInputs>>(null)
  const { canFetchRoleList, canDeleteRole, canFetchUserList } =
    useHasPermission({
      canFetchUserList: PermissionTypes.UserList,
      canFetchRoleList: PermissionTypes.RoleListFunction,
      canDeleteRole: PermissionTypes.RoleDeleteFunction,
    })
  const { data: roleList } = useRoleListQuery({ canFetchRoleList })
  const { data: userList } = useUserListQuery({ canFetchUserList })
  const { mutateAsync: deleteRole } = useDeleteRoleMutation()
  const { mutateAsync: updateUsers } = useUpdateUsersMutation()

  const role = useMemo<IRole | undefined>(
    () => roleList?.find(item => item.id === roleId),
    [roleList, roleId],
  )

  const otherRoles = useMemo<IRole[]>(
    () => roleList?.filter(role => role.id !== roleId) || [],
    [roleList, roleId],
  )

  const usersWithRole = useMemo<IUser[] | undefined>(
    () =>
      userList?.filter(
        user => !!user.roles?.find(userRole => userRole === role?.role),
      ),
    [userList, role],
  )

  const handleDeleteLonelyRole = async () => {
    if (role && canDeleteRole) {
      await deleteRole({ id: roleId })
      notification.success(
        t('roleRemoveFromUsers.notification.removed', {
          roleName: role.role,
        }),
      )
      onSuccess()
    }
  }

  const handleDeleteRoleWithUsers = async () => {
    if (canDeleteRole && role?.role && userList && reassignRoleData) {
      const users = transDataToApiBody(reassignRoleData, {
        otherRoles,
        userList,
        removedRoleName: role.role,
      })
      await updateUsers({ users })
      await handleDeleteLonelyRole()
    }
  }

  const handleCancelDeleteRoleWithUsers = () => setIsRoleReassigned(false)

  const handleReassignRoles = (data: IReassignRoleInputs) => {
    setReassignRoleData(data)
    setIsRoleReassigned(true)
  }

  if (!usersWithRole || !roleList || !role) {
    return <FrankieLoader loading className="min-h-[180px]" />
  }

  if (usersWithRole.length === 0) {
    return (
      <div className="pb-3">
        <RoleRemoveConfirm
          onCancel={onCancel}
          onConfirm={handleDeleteLonelyRole}
          roleName={role.role}
        />
      </div>
    )
  }

  if (isRoleReassigned) {
    return (
      <div className="pb-3">
        <RoleRemoveConfirm
          onCancel={handleCancelDeleteRoleWithUsers}
          onConfirm={handleDeleteRoleWithUsers}
          roleName={role.role}
        />
      </div>
    )
  }

  return (
    <div>
      <ReassignRolesForm
        defaultValues={reassignRoleData}
        roles={otherRoles}
        users={usersWithRole}
        onSubmit={handleReassignRoles}
        onCancel={onCancel}
      />
    </div>
  )
}
