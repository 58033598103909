import { useQuery, useQueryClient } from '@tanstack/react-query'

import { StorageKeyTypes, getLocalStorageState } from 'shared/local-storage'

import { PageData } from '../../model/session.model'

export const SessionQueryKey = ['session']

export const useSessionQuery = () => {
  const queryClient = useQueryClient()

  return useQuery<PageData>({
    queryKey: SessionQueryKey,
    queryFn: () => {
      const cachedSession = queryClient.getQueryData<PageData>(SessionQueryKey)

      if (cachedSession) {
        return cachedSession
      }

      const [pageData] = getLocalStorageState(StorageKeyTypes.PageData)

      if (pageData) {
        return pageData as PageData
      }

      return {} as PageData
    },
  })
}
