import { cva } from 'cva'

export const ContainerStyle = cva([], {
  variants: {
    fullscreen: {
      false: ['relative', 'h-full', 'w-full'],
      true: ['fixed', 'h-screen', 'w-screen', 'top-0', 'left-0', 'z-9'],
    },
  },
})

export const FrankieLoaderStyle = cva(
  ['inline-block', 'animate-spin', 'top-1/2', 'left-1/2', 'text-primary'],
  {
    variants: {
      size: {
        sm: ['w-[50px]', 'h-[50px]'],
        md: ['w-[75px]', 'h-[75px]'],
        lg: ['w-[100px]', 'h-[100px]'],
      },
    },
  },
)
