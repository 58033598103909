import {
  PASSWORD_RESET_PAGE_LOCALE,
  passwordResetPageEn,
} from 'pages/password-reset'
import {
  PASSWORD_RESET_SENT_PAGE_LOCALE,
  passwordResetSentPageEn,
} from 'pages/reset-password-sent'
import {
  SETTINGS_ORGANISATION_PAGE_KEY,
  settingsOrganisationPageEn,
} from 'pages/settings-organisation'
import {
  SETTINGS_PERSONAL_PAGE_KEY,
  settingsPersonalPageEn,
} from 'pages/settings-personal'
import {
  SETTINGS_ROLE_EDIT_PAGE_KEY,
  settingsRoleEditEn,
} from 'pages/settings-role-edit'
import {
  SETTINGS_ROLE_LIST_PAGE_KEY,
  settingsRoleListEn,
} from 'pages/settings-role-list'
import {
  SETTINGS_ROLE_NEW_PAGE_KEY,
  settingsRoleNewEn,
} from 'pages/settings-role-new'
import {
  SETTINGS_ROLE_VIEW_PAGE_KEY,
  settingsRoleViewEn,
} from 'pages/settings-role-view'
import {
  SETTINGS_USER_LIST_PAGE_KEY,
  settingsUserListEn,
} from 'pages/settings-user-list'

export const pages = {
  [PASSWORD_RESET_PAGE_LOCALE]: passwordResetPageEn,
  [PASSWORD_RESET_SENT_PAGE_LOCALE]: passwordResetSentPageEn,
  [SETTINGS_ORGANISATION_PAGE_KEY]: settingsOrganisationPageEn,
  [SETTINGS_PERSONAL_PAGE_KEY]: settingsPersonalPageEn,
  [SETTINGS_ROLE_LIST_PAGE_KEY]: settingsRoleListEn,
  [SETTINGS_USER_LIST_PAGE_KEY]: settingsUserListEn,
  [SETTINGS_ROLE_VIEW_PAGE_KEY]: settingsRoleViewEn,
  [SETTINGS_ROLE_EDIT_PAGE_KEY]: settingsRoleEditEn,
  [SETTINGS_ROLE_NEW_PAGE_KEY]: settingsRoleNewEn,
}
