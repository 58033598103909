import { PermissionTypes } from 'entities/role'

import { useSessionQuery } from '../../state/session/session.query'

/**
 * Useful hook for managing permissions
 *
 * @example
 * const { canDeleteRole, canCreateRole } = useHasPermission({
 *   canDeleteRole: PermissionTypes.RoleDeleteFunction,
 *   canCreateRole: [PermissionTypes.RoleCreateFunction, PermissionTypes.RoleCreateFunction],
 * })
 */
export function useHasPermission<
  T extends { [action: string]: PermissionTypes | PermissionTypes[] },
>(actionMap: T): { [Property in keyof T]: boolean } {
  const { data: session } = useSessionQuery()

  const result = {} as {
    [Property in keyof T]: boolean
  }

  Object.entries(actionMap).forEach(([key, arrOrPermission]) => {
    let flag: boolean
    if (typeof arrOrPermission === 'string') {
      flag = !!session?.permissions?.find(
        permission => permission === arrOrPermission,
      )
    } else {
      flag = arrOrPermission.every(
        required =>
          !!session?.permissions?.find(permission => permission === required),
      )
    }
    result[key as keyof T] = flag
  })

  return result
}
