import React, { useState } from 'react'

import {
  FrankieBadge,
  FrankieButton,
  FrankieToggle,
  useFrankieMenu,
} from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { UserTableRowLayout } from './user-table-row-layout/user-table-row-layout'
import { userQa } from '../../qa/user.qa'
import { USER_KEY } from '../../user.key'

type Props = {
  id: string
  name: string
  email: string
  isLocked: boolean
  hasMfa: boolean
  roles: string[]
  lastLoginDate: Nullable<string>
  createdDate: string
  isGlobal: boolean
  onRemove: (userId: string, userName: string) => void
  onEdit: (userId: string) => void
  onResetPassword: () => Promise<void>
  onChangeLocked: () => Promise<void>
  canEdit: boolean
  canRemove: boolean
  canChangeLocked: boolean
  canSeeRole: boolean
}

// eslint-disable-next-line complexity
export function UserTableRow({
  id,
  name,
  email,
  isLocked,
  roles,
  hasMfa,
  lastLoginDate,
  createdDate,
  isGlobal,
  onRemove,
  onEdit,
  onResetPassword,
  onChangeLocked,
  canEdit,
  canRemove,
  canChangeLocked,
  canSeeRole,
}: Props) {
  const [t] = useI18n([USER_KEY])
  const [toggleDisabled, setToggleDisabled] = useState<boolean>(false)
  const { handleClose, isOpen, parentRef, handleToggle } = useFrankieMenu()

  const handleChangeLocked = async () => {
    try {
      setToggleDisabled(true)
      await onChangeLocked()
      setToggleDisabled(false)
    } finally {
      setToggleDisabled(false)
    }
  }

  const handleEdit = () => {
    handleClose()
    onEdit(id)
  }
  const handleResetPassword = async () => {
    handleClose()
    await onResetPassword()
  }
  const handleRemove = () => {
    handleClose()
    onRemove(id, name)
  }

  return (
    <div className="w-full text-sm leading-5 hover:bg-tertiary-grey-100">
      <UserTableRowLayout
        nameCell={
          <div
            data-qa={userQa.userTableRow_cellName}
            className="px-4 py-3 text-tertiary-grey-700 break-words"
          >
            {name}
          </div>
        }
        emailCell={
          <div
            data-qa={userQa.userTableRow_cellEmail}
            className="px-4 py-3 text-tertiary-grey-700 break-words"
          >
            {email}
          </div>
        }
        isLockedCell={
          <div className="px-4 py-3 flex justify-center text-tertiary-grey-700">
            <FrankieToggle
              disabled={!canChangeLocked || toggleDisabled}
              onChange={handleChangeLocked}
              checked={isLocked}
              size="sm"
              testId={{ input: userQa.userTableRow_cellLocked }}
            />
          </div>
        }
        roleCell={
          canSeeRole ? (
            <div className="break-words px-4 py-3 text-tertiary-grey-700">
              {roles.map(role => (
                <div data-qa={userQa.userTableRow_role} key={role}>
                  {role}
                </div>
              ))}
            </div>
          ) : undefined
        }
        mfaCell={
          <div className="px-4 py-3 text-tertiary-grey-700">
            <FrankieBadge
              theme={hasMfa ? 'green' : 'red'}
              text={t(
                hasMfa ? 'tableRow.mfa.enabled' : 'tableRow.mfa.disabled',
              )}
              testId={{ badge: userQa.userTableRow_cellMfa }}
            />
          </div>
        }
        createdDateCell={
          <div className="px-4 py-3 text-tertiary-grey-700">
            <div data-qa={userQa.userTableRow_cellCreatedAtDate}>
              {formatDate(createdDate, DateFormatTypes.DateNumbers)}
            </div>
            <div
              data-qa={userQa.userTableRow_cellCreatedAtTime}
              className="text-xs text-tertiary-grey-500 leading-none"
            >
              {formatDate(createdDate, DateFormatTypes.Time)}
            </div>
          </div>
        }
        lastLoginDateCell={
          <div className="px-4 py-3 text-tertiary-grey-700">
            {lastLoginDate ? (
              <>
                <div data-qa={userQa.userTableRow_cellLoggedAtDate}>
                  {formatDate(lastLoginDate, DateFormatTypes.DateNumbers)}
                </div>
                <div
                  data-qa={userQa.userTableRow_cellLoggedAtTime}
                  className="text-xs text-tertiary-grey-500 leading-none"
                >
                  {formatDate(lastLoginDate, DateFormatTypes.Time)}
                </div>
              </>
            ) : (
              '-'
            )}
          </div>
        }
        actionsCell={
          isGlobal ? null : (
            <div
              ref={parentRef}
              className="flex h-full justify-center p-2 text-tertiary-grey-700"
            >
              <FrankieButton
                intent="subtle"
                onClick={handleToggle}
                singleIcon={{ name: 'mdiDotsHorizontal', size: 'sm' }}
                circle
                testId={{ button: userQa.userTableRow_ctaActions }}
              />
              <div className="relative">
                {isOpen && (
                  <div className="absolute top-10 right-0 min-w-[224px] rounded-sm bg-mono-white shadow-md py-1">
                    {canEdit && (
                      <FrankieButton
                        onClick={handleEdit}
                        className="text-tertiary-grey-800 w-full text-left px-4 py-2 hover:bg-tertiary-grey-100"
                        noStyles
                        testId={{ button: userQa.userTableRow_ctaEdit }}
                      >
                        {t('tableRow.cta.edit')}
                      </FrankieButton>
                    )}
                    <FrankieButton
                      onClick={handleResetPassword}
                      className="text-tertiary-grey-800 w-full text-left px-4 py-2 hover:bg-tertiary-grey-100"
                      noStyles
                      testId={{ button: userQa.userTableRow_ctaResetPassword }}
                    >
                      {t('tableRow.cta.resetPassword')}
                    </FrankieButton>
                    {canRemove && (
                      <FrankieButton
                        onClick={handleRemove}
                        className="text-tertiary-grey-800 w-full text-left px-4 py-2 hover:bg-tertiary-grey-100"
                        testId={{ button: userQa.userTableRow_ctaDelete }}
                        noStyles
                      >
                        {t('tableRow.cta.remove')}
                      </FrankieButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }
      />
    </div>
  )
}
