import React, { lazy } from 'react'

import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Route,
} from 'react-router-dom'

import { PrivateRoute } from 'app/routing/ui/private-route/private-route'

import { EXPIRED_URL_PARAM } from 'features/reset-password'

import { PermissionTypes, RoleId } from 'entities/role'

import { LayoutAuth } from '../ui/layout-auth/layout-auth'
import { LayoutDefault } from '../ui/layout-default/layout-default'
import { LayoutSettings } from '../ui/layout-settings/layout-settings'
import { PermissionGuard } from '../ui/permission-guard/permission-guard'

const LoginPage = lazy(async () => {
  const { LoginPage } = await import('pages/login')
  return { default: LoginPage }
})

const NewPasswordPage = lazy(async () => {
  const { NewPasswordPage } = await import('pages/new-password')
  return { default: NewPasswordPage }
})

const PasswordResetPage = lazy(async () => {
  const { PasswordResetPage } = await import('pages/password-reset')
  return { default: PasswordResetPage }
})

const PasswordResetSentPage = lazy(async () => {
  const { PasswordResetSentPage } = await import('pages/reset-password-sent')
  return { default: PasswordResetSentPage }
})

const SettingsOrganisationPage = lazy(async () => {
  const { SettingsOrganisationPage } = await import(
    'pages/settings-organisation'
  )
  return { default: SettingsOrganisationPage }
})

const SettingsPersonalPage = lazy(async () => {
  const { SettingsPersonalPage } = await import('pages/settings-personal')
  return { default: SettingsPersonalPage }
})

const SettingsRoleEditPage = lazy(async () => {
  const { SettingsRoleEditPage } = await import('pages/settings-role-edit')
  return { default: SettingsRoleEditPage }
})

const SettingsRoleListPage = lazy(async () => {
  const { SettingsRoleListPage } = await import('pages/settings-role-list')
  return { default: SettingsRoleListPage }
})

const SettingsRoleNewPage = lazy(async () => {
  const { SettingsRoleNewPage } = await import('pages/settings-role-new')
  return { default: SettingsRoleNewPage }
})

const SettingsRoleViewPage = lazy(async () => {
  const { SettingsRoleViewPage } = await import('pages/settings-role-view')
  return { default: SettingsRoleViewPage }
})

const SettingsUserListPage = lazy(async () => {
  const { SettingsUserListPage } = await import('pages/settings-user-list')
  return { default: SettingsUserListPage }
})

const params = {
  roleId: 'roleId',
}

export const paths = {
  login: '/login',
  reset_password: '/reset-password', // page after login
  reset_password_sent: '/reset-password/:email', // password link sent
  new_password: '/new-password',
  onboarding: '/onboarding',
  settings_personal: '/admin/personal',
  settings_organisation: '/admin/settings',
  settings_role_list: '/admin/roles',
  settings_role_new: '/admin/roles/new',
  settings_role_edit: `/admin/roles/:${params.roleId}/edit`,
  settings_role_view: `/admin/roles/:${params.roleId}/view`,
  settings_user_list: '/admin/users',
}

const UnAuthorizedLayout = Route
const SettingsNavLayout = Route
const DefaultLayout = Route

export const CreatedRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <UnAuthorizedLayout element={<LayoutAuth />}>
        <Route
          path={paths.login}
          element={
            <LoginPage
              onboardingPath={paths.onboarding}
              passwordResetPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.reset_password_sent}
          element={
            <PasswordResetSentPage
              loginPath={paths.login}
              errorPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.reset_password}
          element={
            <PasswordResetPage
              loginPath={paths.login}
              afterResetPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.new_password}
          element={
            <NewPasswordPage
              loginPath={paths.login}
              expiredTokenPath={`${paths.reset_password}?${EXPIRED_URL_PARAM}=true`}
            />
          }
        />
        <Route path="*" element={<Navigate to={paths.login} replace />} />
      </UnAuthorizedLayout>

      <DefaultLayout element={<LayoutDefault />}>
        <SettingsNavLayout element={<LayoutSettings />}>
          <Route
            path={paths.settings_personal}
            element={
              <PrivateRoute loginPath={paths.login}>
                <SettingsPersonalPage />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_organisation}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard
                  permissions={PermissionTypes.OrganisationSettingsFetch}
                >
                  <SettingsOrganisationPage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_role_list}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard permissions={PermissionTypes.RoleIndexPage}>
                  <SettingsRoleListPage
                    newRolePath={paths.settings_role_new}
                    getRoleViewPath={(roleId: RoleId) =>
                      generatePath(paths.settings_role_view, { roleId })
                    }
                    getRoleEditPath={(roleId: RoleId) =>
                      generatePath(paths.settings_role_edit, { roleId })
                    }
                  />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_user_list}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard permissions={PermissionTypes.UserIndex}>
                  <SettingsUserListPage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
        </SettingsNavLayout>
        <Route
          path={paths.settings_role_view}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard
                permissions={PermissionTypes.RolePermissionMappingPage}
              >
                <SettingsRoleViewPage
                  roleListPath={paths.settings_role_list}
                  roleIdParamKey={params.roleId}
                  getRoleEditPath={(roleId: RoleId) => ({
                    path: generatePath(paths.settings_role_edit, { roleId }),
                    state: { fromRoleView: true },
                  })}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={paths.settings_role_edit}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard permissions={PermissionTypes.RoleEditPage}>
                <SettingsRoleEditPage
                  roleListPath={paths.settings_role_list}
                  roleIdParamKey={params.roleId}
                  getCancelPathFromState={(state, roleId: RoleId) => {
                    if (
                      state &&
                      state.fromRoleView &&
                      typeof state.fromRoleView === 'boolean' &&
                      state.fromRoleView &&
                      roleId
                    ) {
                      return generatePath(paths.settings_role_view, { roleId })
                    }
                    return paths.settings_role_list
                  }}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={paths.settings_role_new}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard permissions={PermissionTypes.RoleNewPage}>
                <SettingsRoleNewPage roleListPath={paths.settings_role_list} />
              </PermissionGuard>
            </PrivateRoute>
          }
        />
      </DefaultLayout>
    </>,
  ),
)
