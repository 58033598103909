import React from 'react'

import './set-public-path'
import ReactDOMClient from 'react-dom/client'
import 'frankify/src/styles/index.css'
import singleSpaReact from 'single-spa-react'

import { Main } from './main'

export const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Main,
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    return <div>This renders when a catastrophic error occurs</div>
  },
})

export const { bootstrap } = reactLifecycles
export const { mount } = reactLifecycles
export const { unmount } = reactLifecycles
