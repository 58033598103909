import React, { useEffect } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { CountryAlpha3Code, ICountryOption } from 'entities/country'

import { useI18n } from 'shared/i18n'

import { CountrySelector } from './country-selector/country-selector'
import { ORGANISATION_SETTINGS_KEY } from '../../organisation-settings.key'
import { organisationSettingsQa } from '../../qa/organisation-settings.qa'

enum DefaultCountryFormInputTypes {
  Country = 'country',
}

interface IDefaultCountryFormInputs {
  [DefaultCountryFormInputTypes.Country]: CountryAlpha3Code
}

type Props = {
  currentCountry?: CountryAlpha3Code
  countryOptions: ICountryOption[]
  onSubmit: (newDefaultCountry: CountryAlpha3Code) => Promise<void>
  disabled?: boolean
}

export function DefaultCountryForm({
  currentCountry,
  countryOptions,
  onSubmit,
  disabled = false,
}: Props) {
  const [t] = useI18n([ORGANISATION_SETTINGS_KEY])

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<IDefaultCountryFormInputs>({
    shouldUnregister: true,
    defaultValues: {
      [DefaultCountryFormInputTypes.Country]: currentCountry,
    },
  })

  useEffect(() => {
    reset({
      [DefaultCountryFormInputTypes.Country]: currentCountry,
    })
  }, [currentCountry, reset])

  const submitDisabled: boolean = disabled || !isDirty || isSubmitting

  const handleFormSubmit: SubmitHandler<IDefaultCountryFormInputs> = async ({
    country,
  }) => {
    await onSubmit(country)
  }

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex flex-initial flex-col gap-3"
    >
      <div className="text-tertiary-grey-800 text-md font-semibold">
        {t('defaultCountryForm.title')}
      </div>
      <Controller
        control={control}
        render={({ field: { value, name, ref, onBlur, onChange } }) => (
          <CountrySelector
            value={value}
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            options={countryOptions}
            disabled={disabled}
          />
        )}
        name={DefaultCountryFormInputTypes.Country}
      />
      <div className="text-sm text-tertiary-grey-700">
        {t('defaultCountryForm.description')}
      </div>
      <FrankieButton
        className="w-fit"
        intent="secondary"
        size="sm"
        type="submit"
        disabled={submitDisabled}
        testId={{ button: organisationSettingsQa.defaultCountryFormCtaSubmit }}
      >
        {t('defaultCountryForm.cta.submit')}
      </FrankieButton>
    </form>
  )
}
