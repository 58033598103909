import React from 'react'

import {
  FrankieTableHeaderCell,
  FrankieTableHeaderCellSortValues,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { roleQa } from '../../../qa/role.qa'
import { ROLE_KEY } from '../../../role.key'
import { RoleTableRowLayout } from '../role-table-row-layout/role-table-row-layout'

export enum RoleSortByTypes {
  Name = 'name',
  OrganisationName = 'organisationName',
  CreatedDate = 'createdDate',
}

type Props = {
  sorts: Record<RoleSortByTypes, FrankieTableHeaderCellSortValues>
  onChangeSort: (sortBy: RoleSortByTypes) => () => void
}

export function RoleTableRowHeader({ sorts, onChangeSort }: Props) {
  const [t] = useI18n([ROLE_KEY])

  return (
    <div className="w-full text-xs font-medium text-tertiary-grey-500 leading-4 bg-tertiary-grey-50">
      <RoleTableRowLayout
        nameCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(RoleSortByTypes.Name)}
            sortValue={sorts[RoleSortByTypes.Name]}
            testId={{
              sort: roleQa.roleTableHeader_sortByName,
            }}
          >
            {t('tableRowHeader.role')}
          </FrankieTableHeaderCell>
        }
        descriptionCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.description')}
          </FrankieTableHeaderCell>
        }
        createdByCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(RoleSortByTypes.OrganisationName)}
            sortValue={sorts[RoleSortByTypes.OrganisationName]}
            testId={{
              sort: roleQa.roleTableHeader_sortByOrgName,
            }}
          >
            {t('tableRowHeader.createdBy')}
          </FrankieTableHeaderCell>
        }
        createdDateCell={
          <FrankieTableHeaderCell
            onClickSort={onChangeSort(RoleSortByTypes.CreatedDate)}
            sortValue={sorts[RoleSortByTypes.CreatedDate]}
            testId={{
              sort: roleQa.roleTableHeader_sortByCreatedDate,
            }}
          >
            {t('tableRowHeader.createdDate')}
          </FrankieTableHeaderCell>
        }
        actionsCell={
          <FrankieTableHeaderCell>
            {t('tableRowHeader.actions')}
          </FrankieTableHeaderCell>
        }
      />
    </div>
  )
}
