import React, { useMemo } from 'react'

import { FrankieLoader } from 'frankify/src'

import type { IOrganisationSetting } from 'features/organisation-settings'
import {
  DefaultCountryForm,
  OrganisationSettingTypes,
  useOrganisationSettingsQuery,
  useUpdateOrganisationSettingMutation,
} from 'features/organisation-settings'

import { CountryAlpha3Code, countryList } from 'entities/country'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { SETTINGS_ORGANISATION_PAGE_KEY } from '../../settings-organisation.key'

export function SettingsOrganisationPage() {
  const [t] = useI18n([SETTINGS_ORGANISATION_PAGE_KEY])
  const { canFetchOrganisationSettings, canUpdateOrganisationSettings } =
    useHasPermission({
      canFetchOrganisationSettings: PermissionTypes.OrganisationSettingsFetch,
      canUpdateOrganisationSettings: PermissionTypes.OrganisationSettingsUpdate,
    })
  const { data: organisationSettings, isLoading } =
    useOrganisationSettingsQuery({ canFetchOrganisationSettings })
  const { mutateAsync: updateSetting } = useUpdateOrganisationSettingMutation()

  const countrySetting = useMemo<IOrganisationSetting | undefined>(
    () =>
      organisationSettings?.find(
        setting => setting.name === OrganisationSettingTypes.Country,
      ),
    [organisationSettings],
  )

  if (isLoading) {
    return <FrankieLoader loading fullscreen />
  }

  const handleSubmit = async (countryCode: CountryAlpha3Code) => {
    if (!canUpdateOrganisationSettings) {
      return
    }
    await updateSetting({
      patch: { value: countryCode, name: OrganisationSettingTypes.Country },
    })
    notification.success(t('notification.defaultCountrySaved'))
  }

  return (
    <div className="p-8">
      <div className="w-[443px]">
        <DefaultCountryForm
          currentCountry={countrySetting?.value}
          countryOptions={countryList}
          disabled={!canUpdateOrganisationSettings}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}
