import React, { useMemo } from 'react'

import { FrankieButtonStyle } from './button.theme'
import { IFrankieButtonProps } from './button.types'
import { FrankieIcon } from '../icon'

export function FrankieButton({
  notAButton = false,
  noStyles = false,
  className = '',
  onClick,
  intent = 'primary',
  size = 'md',
  disabled = false,
  circle = false,
  singleIcon,
  startIcon,
  endIcon,
  children,
  type = 'button',
  name = '',
  testId = {
    button: '',
  },
}: IFrankieButtonProps): JSX.Element {
  const finalClassName = noStyles
    ? className
    : FrankieButtonStyle({
        size,
        intent,
        icon: singleIcon ? size : null,
        circle,
        className,
      })

  const content = useMemo(
    () => (
      <>
        {startIcon && <FrankieIcon {...startIcon} />}
        <div>{singleIcon ? <FrankieIcon {...singleIcon} /> : children}</div>
        {endIcon && <FrankieIcon {...endIcon} />}
      </>
    ),
    [children, endIcon, singleIcon, startIcon],
  )

  if (notAButton) {
    return (
      <div className={finalClassName} data-qa={testId.button}>
        {content}
      </div>
    )
  }
  /* eslint-disable react/button-has-type */
  return (
    <button
      name={name}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={finalClassName}
      data-qa={testId.button}
    >
      {content}
    </button>
  )
  /* eslint-enable react/button-has-type */
}
