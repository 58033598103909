import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'

import { FrankieButton, FrankieTextField } from 'frankify/src'

import { CreateUserModal } from 'fwidgets/roles-to-users'

import { resetPasswordApi } from 'features/reset-password'
import { UserTable, useUserListQuery } from 'features/user-management'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'
import { IUser } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { settingsUserListQa } from '../../qa/settings-user-list.qa'
import { SETTINGS_USER_LIST_PAGE_KEY } from '../../settings-user-list.key'

export function SettingsUserListPage(): ReactElement {
  const [t] = useI18n([SETTINGS_USER_LIST_PAGE_KEY])
  const [search, setSearch] = useState<string>('')
  const [usersSearched, setUsersSearched] = useState<IUser[]>([])

  const {
    canFetchUserList,
    canRemoveUser,
    canEditUser,
    canLockUser,
    canCreateUser,
    canSeeRole,
  } = useHasPermission({
    canFetchUserList: PermissionTypes.UserList,
    canRemoveUser: PermissionTypes.UserDelete,
    canLockUser: PermissionTypes.UserLock,
    canCreateUser: PermissionTypes.UserAdd,
    canEditUser: PermissionTypes.UserEdit,
    canSeeRole: PermissionTypes.RoleUserPermissionListPage,
  })

  const { data: userList } = useUserListQuery({ canFetchUserList })
  const [createOverlay, closeOverlay] = useOverlay()

  useEffect(() => {
    if (userList) {
      setUsersSearched(
        userList.filter(
          user =>
            user.realname.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase()),
        ),
      )
    }
  }, [search, userList])

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value)

  const handleResetUserPassword = async (email: string) =>
    resetPasswordApi.requestResetPasswordLink(email)

  const handleCreateNewUser = () => {
    createOverlay(
      <CreateUserModal onCancel={closeOverlay} userList={userList || []} />,
      {
        className: 'p-8 w-[640px]',
      },
    )
  }

  const handleEditUser = (user: IUser) => {
    createOverlay(
      <CreateUserModal
        onCancel={closeOverlay}
        userToEdit={user}
        userList={userList || []}
      />,
      {
        className: 'p-8 w-[640px]',
      },
    )
  }

  return (
    <div className="p-8 pr-0">
      <div className="mb-4 pr-8 flex w-full flex-initial flex-row justify-between items-center">
        <div className="w-[326px]">
          <FrankieTextField
            size="sm"
            type="search"
            value={search}
            onChange={handleChangeSearch}
            testId={{ input: settingsUserListQa.searchUserInput }}
            isSearchIcon
            placeholder={t('input.searchUser')}
          />
        </div>
        {canCreateUser && (
          <FrankieButton
            testId={{ button: settingsUserListQa.createUserCta }}
            size="sm"
            intent="secondary"
            onClick={handleCreateNewUser}
          >
            {t('cta.createUser')}
          </FrankieButton>
        )}
      </div>
      <UserTable
        canLock={canLockUser}
        canRemove={canRemoveUser}
        canEdit={canEditUser}
        canSeeRole={canSeeRole}
        onResetUserPassword={handleResetUserPassword}
        onEditUser={handleEditUser}
        users={usersSearched}
      />
    </div>
  )
}
