import React from 'react'

import { Link } from 'react-router-dom'

import { FrankieButton, useFrankieMenu } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { RoleTableRowLayout } from './role-table-row-layout/role-table-row-layout'
import { roleQa } from '../../qa/role.qa'
import { ROLE_KEY } from '../../role.key'

type Props = {
  isChildOrganisation: boolean
  name: string
  description: string
  createdBy: Nullable<string>
  createdDate: string
  linkTo: string
  isGlobal: boolean
  linkToEdit: string
  onRemove: () => void
  canRemove: boolean
  canView: boolean
  canEdit: boolean
}

// eslint-disable-next-line complexity
export function RoleTableRow({
  name,
  description,
  createdBy,
  createdDate,
  linkTo,
  isGlobal,
  isChildOrganisation,
  linkToEdit,
  onRemove,
  canRemove,
  canView,
  canEdit,
}: Props) {
  const [t] = useI18n([ROLE_KEY])
  const { handleClose, isOpen, parentRef, handleToggle } = useFrankieMenu()

  const showActions =
    !isGlobal && !isChildOrganisation && (canEdit || canRemove)

  const handleRemove = () => {
    handleClose()
    onRemove()
  }

  return (
    <div className="w-full text-sm leading-5 hover:bg-tertiary-grey-100">
      <RoleTableRowLayout
        linkTo={canView ? linkTo : undefined}
        nameCell={
          <div
            className="px-4 py-3 text-tertiary-grey-700 break-words"
            data-qa={roleQa.roleTableRow_cellName}
          >
            {name}
          </div>
        }
        descriptionCell={
          <div
            className="px-4 py-3 text-tertiary-grey-700 break-words"
            data-qa={roleQa.roleTableRow_cellDescription}
          >
            {description}
          </div>
        }
        createdByCell={
          <div
            className="px-4 py-3 text-tertiary-grey-700 break-words"
            data-qa={roleQa.roleTableRow_cellCreatedBy}
          >
            {isGlobal ? t('tableRow.createdBy.frankie') : createdBy}
          </div>
        }
        createdDateCell={
          <div className="px-4 py-3 text-tertiary-grey-700 min-h-[57px]">
            <div data-qa={roleQa.roleTableRow_cellCreatedAtDate}>
              {isGlobal
                ? '-'
                : formatDate(createdDate, DateFormatTypes.DateNumbers)}
            </div>
            {!isGlobal && (
              <div
                className="text-xs text-tertiary-grey-500 leading-none"
                data-qa={roleQa.roleTableRow_cellCreatedAtTime}
              >
                {formatDate(createdDate, DateFormatTypes.Time)}
              </div>
            )}
          </div>
        }
        actionsCell={
          showActions ? (
            <div
              ref={parentRef}
              className="flex h-full justify-center p-2 text-tertiary-grey-700"
            >
              <FrankieButton
                intent="subtle"
                onClick={handleToggle}
                singleIcon={{ name: 'mdiDotsHorizontal', size: 'sm' }}
                testId={{
                  button: roleQa.roleTableRow_ctaActions,
                }}
                circle
              />
              <div className="relative">
                {isOpen && (
                  <div className="absolute top-10 right-0 min-w-[224px] rounded-sm bg-mono-white shadow-md py-1">
                    {canEdit && (
                      <Link
                        data-qa={roleQa.roleTableRow_ctaEdit}
                        to={linkToEdit}
                      >
                        <div className="text-tertiary-grey-800 px-4 py-2 hover:bg-tertiary-grey-100">
                          {t('tableRow.cta.edit')}
                        </div>
                      </Link>
                    )}
                    {canRemove && (
                      <FrankieButton
                        noStyles
                        onClick={handleRemove}
                        className="text-tertiary-grey-800 w-full text-left px-4 py-2 hover:bg-tertiary-grey-100"
                        testId={{
                          button: roleQa.roleTableRow_ctaDelete,
                        }}
                      >
                        {t('tableRow.cta.remove')}
                      </FrankieButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null
        }
      />
    </div>
  )
}
