import React, { Suspense, useCallback, useEffect, useState } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'

import { FrankieLoader } from 'frankify/src'

import { i18n, I18nProvider } from 'app/i18n'
import { CreatedRouter, paths } from 'app/routing'
import { initQueryClient } from 'app/state-management'
// These styles are used in the portal-vue application,
// there are some conflicts with tailwind styles.
import 'app/portal-vue-compatibility/breaking-styles.css'

import { bffClient } from 'shared/client'
import { localStorageClear } from 'shared/local-storage'
import { NotificationContainer } from 'shared/notification'
import { OverlayProvider } from 'shared/overlay'
import { Nullable } from 'shared/typescript'

function clearAppState(resetQueryClient: () => void) {
  bffClient.clearToken()
  localStorageClear()
  resetQueryClient()
  window.location.replace(paths.login)
}

export function Main(): JSX.Element | null {
  const [queryClient, setQueryClient] = useState<Nullable<QueryClient>>(null)

  /**
   * When any request fails with invalid token error,
   * clears application state completely and re-inits query client
   */
  const setQueryClientPersistently = useCallback(() => {
    setQueryClient(
      initQueryClient({
        onInvalidTokenError: () => {
          clearAppState(setQueryClientPersistently)
        },
      }),
    )
  }, [])

  // called once
  useEffect(() => {
    setQueryClientPersistently()
  }, [setQueryClientPersistently])

  if (queryClient) {
    return (
      <div id="portal-module-react-tailwind">
        <I18nProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <OverlayProvider>
              <Suspense fallback={<FrankieLoader loading />}>
                <RouterProvider router={CreatedRouter} />
              </Suspense>
              <NotificationContainer />
            </OverlayProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </I18nProvider>
      </div>
    )
  }

  return null
}
