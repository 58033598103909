import React, { useRef } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { IOverlayProps } from '../../model/overlay.model'
import { overlayQa } from '../../qa/overlay.qa'

export function Overlay({ children, onClose, className = '' }: IOverlayProps) {
  const overlayRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    onClose()
  }

  return (
    <div
      className="fixed z-[1000] bg-mono-black/60 w-screen h-screen top-0 left-0 right-0 bottom-0 flex flex-initial items-center justify-center"
      ref={overlayRef}
      data-qa={overlayQa.container}
    >
      <div className="relative max-h-full bg-mono-white rounded-2">
        <FrankieButton
          noStyles
          onClick={handleClose}
          className="absolute top-2 right-2 text-tertiary-grey-400 hover:text-tertiary-grey-800"
          testId={{ button: overlayQa.closeCta }}
        >
          <FrankieIcon name="mdiClose" />
        </FrankieButton>
        <div className={className || 'p-8 overflow-y-auto'}>{children}</div>
      </div>
    </div>
  )
}
