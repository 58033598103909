import React from 'react'

import Icon from '@mdi/react'

import {
  IFrankieIconSvgProps,
  IFrankieIconProps,
  FrankieIconMdiEnum,
  FrankieIconMuiEnum,
  IFrankieIconSizeProps,
  MdiIcons,
  MuiIcons,
  isFrankieIconUnionSize,
} from './icon.types'
import { icon as iconRemSizing } from '../../styles/tailwind/fontSize'

const IconSizeDictionary = {
  '2xs': 'text-icon-2xs',
  xs: 'text-icon-xs',
  sm: 'text-icon-sm',
  md: 'text-icon-md',
  lg: 'text-icon-lg',
  xl: 'text-icon-xl',
}

export const IconSpaceDictionary = {
  '2xs': ['w-icon-2xs', 'h-icon-2xs'],
  xs: ['w-icon-xs', 'h-icon-xs'],
  sm: ['w-icon-sm', 'h-icon-sm'],
  md: ['w-icon-md', 'h-icon-md'],
  lg: ['w-icon-lg', 'h-icon-lg'],
  xl: ['w-icon-xl', 'h-icon-xl'],
}

export const FrankieIcon = ({
  className = '',
  library = 'mdi',
  name,
  size = 'md',
  testId = { icon: '' },
}: IFrankieIconProps): JSX.Element => {
  // Retrieve the specific component based on the library
  const getComponent = ({
    className,
    library,
    name,
  }: IFrankieIconSvgProps): ((props: any) => JSX.Element) | null => {
    const iconDictionary = {
      mdi: MdiIcons,
      mui: MuiIcons,
    }

    // Should return nothing if the given icon doesn't exist in the given library
    if (!Object.keys(iconDictionary[library]).includes(name)) {
      return null
    }

    const componentDictionary = {
      mdi: (props: any) => {
        const iconSvg = MdiIcons[name as FrankieIconMdiEnum]
        return (
          <div className={className}>
            <Icon {...props} path={iconSvg} data-qa={testId.icon} />
          </div>
        )
      },

      mui: (props: any) => {
        const Icon = MuiIcons[name as FrankieIconMuiEnum]
        return (
          <Icon
            {...props}
            classes={{ root: className }}
            data-qa={testId.icon}
          />
        )
      },
    }

    return componentDictionary[library]
  }

  // Override the sizing of the given component based on the library so all icon sizing are consistent
  const applySizeOverride = ({
    icon: Icon,
    library,
    size,
  }: IFrankieIconSizeProps): JSX.Element => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    const SizeOverrideIcon: JSX.Element = <></>
    if (Icon == null) {
      return SizeOverrideIcon
    }

    const sizeInTailwindClass = isFrankieIconUnionSize(size)
      ? IconSizeDictionary[size]
      : `text-[${size}]`
    const sizeInCssUnits = isFrankieIconUnionSize(size)
      ? iconRemSizing[`icon-${size}`]
      : size

    const overrideDictionary = {
      mdi: <Icon size={sizeInCssUnits} />,
      mui: (
        <div className={`${sizeInTailwindClass}`}>
          {/*
          The underlying mui icon should have empty fontSize so it can inherit parent
          it should also have display block so it doesn't add extra spacing for parent div
        */}
          <Icon fontSize="inherit" sx={{ display: 'block' }} />
        </div>
      ),
    }

    return overrideDictionary[library]
  }

  const icon = getComponent({ className, library, name })
  return applySizeOverride({ icon, library, size })
}
