import React, { FormEvent, forwardRef, useState } from 'react'

import { FrankieTextFieldStyle } from './text-field.theme'
import { IFrankieTextFieldProps } from './text-field.types'
import { FrankieButton } from '../button'
import { FrankieIcon } from '../icon'

import './text-field.css'

export const FrankieTextField = forwardRef(
  // eslint-disable-next-line complexity, prefer-arrow-callback
  function FrankieTextField(
    {
      className = '',
      inputClassName = '',
      size = 'md',
      counter,
      disabled = false,
      error = false,
      errorText = '',
      label,
      name,
      placeholder = '',
      readOnly = false,
      supportingText = '',
      tag,
      isSearchIcon,
      testId = {
        container: '',
        input: '',
        label: '',
        tag: '',
        showPassword: '',
        supportingText: '',
        counter: '',
      },
      type = 'text',
      value,
      onBlur,
      onChange,
      onClick,
      onFocus,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      required,
      autoComplete,
    }: IFrankieTextFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    const [inputLength, setInputLength] = useState(0)
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
      setInputLength(e.currentTarget.value.length)
    }

    return (
      <div className={`w-full ${className}`} data-qa={testId.container}>
        <div className="flex flex-initial pb-1.5 w-icon-sm">
          <div className="grow">
            <label
              htmlFor={name}
              className="text-tertiary-grey-800 text-sm font-medium"
              data-qa={testId.label}
            >
              {label}
            </label>
          </div>
          <div
            className="flex flex-initial grow justify-end text-mono-70"
            data-qa={testId.tag}
          >
            {tag}
          </div>
        </div>
        <div className="relative frankie-text-field-container">
          <input
            id={name}
            name={name}
            aria-label={name}
            ref={ref}
            className={FrankieTextFieldStyle({
              inputError: error,
              startIcon: isSearchIcon,
              className: inputClassName,
              size,
            })}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            type={passwordVisibility ? 'text' : type}
            onInput={handleInput}
            value={value}
            data-qa={testId.input}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            min={min}
            max={max}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
            required={required}
            autoComplete={autoComplete}
          />
          {type === 'search' && isSearchIcon && (
            <div className="absolute top-2.5 left-4 text-tertiary-grey-500">
              <FrankieIcon size="xs" name="mdiMagnify" />
            </div>
          )}
          {type === 'password' && (
            <div className="absolute top-0 right-2">
              <FrankieButton
                intent="subtle"
                size="md"
                testId={{ button: testId.showPassword }}
                className="text-tertiary-grey-500"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                singleIcon={{
                  size: 'xs',
                  name: passwordVisibility
                    ? 'mdiEyeOffOutline'
                    : 'mdiEyeOutline',
                }}
              />
            </div>
          )}
        </div>
        <div className="flex">
          {(supportingText || errorText) && (
            <div className="grow text-mono-70 pt-2 text-sm">
              <div
                className={`flex flex-initial items-center ${
                  error ? 'text-tertiary-red-700' : ''
                }`}
              >
                <div className="p-0.5 mr-2">
                  <FrankieIcon name="mdiInformationOutline" size="sm" />
                </div>
                <div data-qa={testId.supportingText}>
                  {error ? errorText : supportingText}
                </div>
              </div>
            </div>
          )}
          <div
            className="flex flex-initial grow justify-end text-mono-70"
            data-qa={testId.counter}
          >
            {typeof counter === 'number' && counter > 0 ? (
              <div
                className={inputLength > counter ? 'text-tertiary-red-700' : ''}
              >
                {inputLength}/{counter}
              </div>
            ) : (
              counter
            )}
          </div>
        </div>
      </div>
    )
  },
)
