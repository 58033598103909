/* eslint-disable complexity */
import React, { useState } from 'react'

import axios from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  FrankieButton,
  FrankieIcon,
  FrankieTextField,
  FrankieAlert,
  FrankieLoader,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { newPasswordApi } from '../../api/new-password.api'
import { NEW_PASSWORD_LOCALE } from '../../locale/new-password.en'
import {
  UrlParamsTypes,
  FormFields,
  ValidationTypes,
  FormFieldTypes,
  ValidationStates,
  KnownErrorMessageTypes,
} from '../../model/new-password.model'
import { newPasswordQA } from '../../qa/new-password.qa'

type Props = {
  onNavigateToLogin: () => void
}

export function NewPasswordForm({ onNavigateToLogin }: Props): JSX.Element {
  const [t] = useI18n([NEW_PASSWORD_LOCALE])

  const [searchParams] = useSearchParams()
  const [serverError, setErrorMessage] = useState<string>('')
  const [isPasswordSaved, togglePasswordSave] = useState(false)

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<FormFields>({
    mode: 'onBlur',
  })

  const [newPasswordValidationErrors, setValidationErrors] = useState<string[]>(
    [
      ValidationTypes.HasLowerCase,
      ValidationTypes.HasNumeric,
      ValidationTypes.HasSpecialChar,
      ValidationTypes.HasUpperCase,
      ValidationTypes.MinLength,
    ],
  )

  const newPasswordRegister = register(FormFieldTypes.NewPassword, {
    maxLength: 0,
    minLength: 0,
    validate: value => {
      const validations: Record<string, (v: string) => boolean> = {
        hasUpperCase: (v: string) => /[A-Z]/.test(v),
        hasLowerCase: (v: string) => /[a-z]/.test(v),
        hasNumeric: (v: string) => /\d/.test(v),
        hasSpecialChar: (v: string) => /[^\w\\s]/.test(v),
        minLength: (v: string) => v.length >= 12,
      }

      const errors: string[] = []

      for (const validation in validations) {
        if (Object.hasOwn(validations, validation)) {
          const validationResult = validations[validation](value)

          if (!validationResult) {
            errors.push(validation)
          }
        }
      }

      setValidationErrors(errors)

      return errors.length === 0
    },
  })

  const [newPasswordFieldBlurred, toggleNewPasswordFieldBlur] = useState(false)

  const confirmPasswordRegister = register(FormFieldTypes.ConfirmPassword, {
    required: {
      value: true,
      message: t('validation.email.required'),
    },
    onChange: async e => {
      const formValues = getValues()

      const areSame =
        formValues[FormFieldTypes.NewPassword] ===
        formValues[FormFieldTypes.ConfirmPassword]

      if (areSame) {
        toggleNewPasswordFieldBlur(true)
      }

      if (!newPasswordFieldBlurred) return

      await trigger(FormFieldTypes.ConfirmPassword)
    },
    onBlur: () => toggleNewPasswordFieldBlur(true),
    validate: (_, formValues) =>
      formValues[FormFieldTypes.NewPassword] ===
      formValues[FormFieldTypes.ConfirmPassword],
  })

  // This array contains the label to display and the relevant key for validationStates
  type ValidationDisplays = {
    label: string
    stateKey: keyof ValidationStates
  }
  const validationDisplays: ValidationDisplays[] = [
    {
      label: t('form.input.newPassword.validation.minimum'),
      stateKey: ValidationTypes.MinLength,
    },
    {
      label: t('form.input.newPassword.validation.uppercase'),
      stateKey: ValidationTypes.HasUpperCase,
    },
    {
      label: t('form.input.newPassword.validation.lowercase'),
      stateKey: ValidationTypes.HasLowerCase,
    },
    {
      label: t('form.input.newPassword.validation.numeric'),
      stateKey: ValidationTypes.HasNumeric,
    },
    {
      label: t('form.input.newPassword.validation.special'),
      stateKey: ValidationTypes.HasSpecialChar,
    },
  ]

  const newUserSubmitText = isSubmitting
    ? t('form.button.submit.loading.new')
    : t('form.button.submit.default.new')

  const handleFormSubmit: SubmitHandler<FormFields> = async ({
    newPassword,
  }) => {
    try {
      const token = searchParams.get(UrlParamsTypes.Token)
      const email = searchParams.get(UrlParamsTypes.Email)

      if (!token || !email) {
        throw new Error('there was an error on password reset')
      }
      setErrorMessage('')

      await newPasswordApi.resetPassword(token, email, newPassword)
      togglePasswordSave(true)
    } catch (error: unknown) {
      if (
        axios.isAxiosError<{ message: string }>(error) &&
        error.response?.data
      ) {
        if (
          'message' in error.response.data &&
          Object.values(KnownErrorMessageTypes).includes(
            error.response.data.message as KnownErrorMessageTypes,
          )
        ) {
          setErrorMessage(
            t(`form.${error.response.data.message as KnownErrorMessageTypes}`),
          )
        }
      } else {
        setErrorMessage(t('form.commonError'))
      }
    }
  }

  const isFieldValid = (validationKey: string) =>
    isDirty && !newPasswordValidationErrors.includes(validationKey)

  const getErrorForNewPassword = () =>
    isDirty &&
    !!errors[FormFieldTypes.NewPassword] &&
    newPasswordValidationErrors.length > 0
      ? t('form.validationError')
      : ''

  const getConfirmFieldError = () =>
    isDirty && !!errors[FormFieldTypes.ConfirmPassword]
      ? t('form.passwordMatchError')
      : ''

  if (isPasswordSaved) {
    return (
      <div>
        <div
          className="text-primary-700 text-3xl font-bold leading-9"
          data-qa={newPasswordQA.successfulResetHeader}
        >
          {t('form.passwordSavedTitle')}
        </div>
        <div className="text-tertiary-grey-700 text-md font-normal mb-3 mt-2">
          {t('form.passwordSavedSubtitle')}
        </div>
        <FrankieButton
          testId={{ button: newPasswordQA.continueButton }}
          onClick={onNavigateToLogin}
          className="mt-6"
        >
          {t('form.continueButton')}
        </FrankieButton>
      </div>
    )
  }

  return (
    <FrankieLoader loading={isSubmitting}>
      <div>
        <div
          className="text-primary-700 text-3xl font-bold"
          data-qa={newPasswordQA.header}
        >
          {t('container.heading.new')}
        </div>
        <div className="text-tertiary-grey-700 text-md font-normal mb-3">
          {t('container.description.new')}
        </div>
        {validationDisplays.map(({ label, stateKey }) => (
          <div
            className="flex flex-initial mt-1 items-center"
            key={label}
            data-qa={`${newPasswordQA.newPasswordRequirement}-${stateKey}`}
          >
            <FrankieIcon
              className={
                isFieldValid(stateKey)
                  ? 'text-tertiary-green-600'
                  : 'text-tertiary-grey-600'
              }
              name={
                isFieldValid(stateKey)
                  ? 'mdiCheckCircle'
                  : 'mdiCheckCircleOutline'
              }
              size="sm"
            />
            <div className="ml-3 text-sm font-normal text-tertiary-grey-600">
              {label}
            </div>
          </div>
        ))}

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          data-qa={newPasswordQA.form}
        >
          <FrankieTextField
            {...newPasswordRegister}
            className="mt-6"
            label={t('form.input.newPassword.label')}
            type="password"
            errorText={getErrorForNewPassword()}
            error={
              isDirty &&
              !!errors[FormFieldTypes.NewPassword] &&
              newPasswordValidationErrors.length > 0
            }
            testId={{
              input: newPasswordQA.newPasswordInput,
              supportingText: newPasswordQA.newPasswordInputErrorText,
            }}
          />
          <FrankieTextField
            {...confirmPasswordRegister}
            className="mt-6"
            label={t('form.input.confirmPassword.label')}
            type="password"
            errorText={getConfirmFieldError()}
            error={isDirty && !!errors[FormFieldTypes.ConfirmPassword]}
            testId={{
              input: newPasswordQA.newPasswordConfirm,
              supportingText: newPasswordQA.confirmPasswordInputErrorText,
            }}
          />
          {serverError ? (
            <FrankieAlert
              className="mt-4 text-sm font-normal text-tertiary-grey-700"
              type="error"
              testId={{ alert: newPasswordQA.alert }}
            >
              <Trans className="text-sm font-normal text-tertiary-grey-700">
                {serverError}
              </Trans>
            </FrankieAlert>
          ) : null}
          <div className="flex flex-initial flex-col-reverse gap-4 tablet:flex-row tablet:justify-end mt-4">
            <FrankieButton intent="subtle" onClick={onNavigateToLogin}>
              {t('form.button.backToLogin.default')}
            </FrankieButton>
            <FrankieButton
              type="submit"
              disabled={isSubmitting || !isValid}
              testId={{ button: newPasswordQA.submitButton }}
            >
              {newUserSubmitText}
            </FrankieButton>
          </div>
        </form>
      </div>
    </FrankieLoader>
  )
}
