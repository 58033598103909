import { cva } from 'cva'

export const FrankieTooltipStyle = cva(
  [
    'frankie-tooltip',
    'w-[156px]',
    'break-words',
    'absolute',
    'py-2.5',
    'px-3',
    'z-[100]',
    'rounded-sm',
    'bg-tertiary-grey-800',
    'after:content-[""]',
    'after:absolute',
    'after:block',
    'after:w-3',
    'after:h-3',
    'after:rotate-45',
    'after:bg-tertiary-grey-800',
  ],
  {
    variants: {
      position: {
        top: [
          '-translate-x-[calc(50%)]',
          '-translate-y-[calc(100%)]',
          'after:top-full',
          'after:right-1/2',
          'after:translate-x-1/2',
          'after:-translate-y-2',
        ],
        right: [
          'left-full',
          'top-1/2',
          '-translate-y-1/2',
          'ml-2',
          'after:right-full',
          'after:top-1/2',
          'after:translate-x-2',
          'after:-translate-y-1/2',
        ],
        bottom: [
          'top-full',
          'right-1/2',
          'translate-x-1/2',
          'mt-2',
          'after:bottom-full',
          'after:right-1/2',
          'after:translate-x-1/2',
          'after:translate-y-[9px]',
        ],
        left: [
          'right-full',
          'top-1/2',
          '-translate-y-1/2',
          'mr-2',
          'after:left-full',
          'after:top-1/2',
          'after:-translate-x-2',
          'after:-translate-y-1/2',
        ],
      },
    },
  },
)
