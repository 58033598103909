import { format, isBefore, minutesToMilliseconds } from 'date-fns'

export { isBefore, minutesToMilliseconds }

export enum DateFormatTypes {
  DateNumbers = 'dd-MM-yyyy', // 20-10-2000
  Time = 'hh:mm aaa', // 01:20 am
}

export function formatDate(date: string, dateFormat: DateFormatTypes): string {
  return format(new Date(date), dateFormat)
}
