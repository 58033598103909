import React from 'react'

import { FrankieInteractiveCard } from 'frankify/src'

import { MfaTypes } from 'entities/mfa'

import { useI18n } from 'shared/i18n'

import { MFA_KEY } from '../../../mfa.key'
import { mfaQa } from '../../../qa/mfa.qa'

type Props = {
  onSelect: (type: MfaTypes) => void
}

export function MfaTypeSelect({ onSelect }: Props) {
  const [t] = useI18n(MFA_KEY)

  const handleSelect = (type: MfaTypes) => () => {
    onSelect(type)
  }

  return (
    <div className="flex flex-initial flex-col gap-6">
      <div>
        <div
          data-qa={mfaQa.mfaTypeSelect_header}
          className="text-2xl text-tertiary-grey-800 font-bold leading-8 mb-4"
        >
          {t('mfaTypeSelect.heading')}
        </div>
        <div className="text-sm text-tertiary-grey-700">
          {t('mfaTypeSelect.subheading')}
        </div>
      </div>
      <FrankieInteractiveCard
        onClick={handleSelect(MfaTypes.App)}
        title={t('mfaTypeSelect.options.app.name')}
        helperText={t('mfaTypeSelect.options.app.descr')}
        startIcon={{ name: 'mdiCellphoneKey', size: 'lg' }}
        testId={{ card: mfaQa.mfaTypeSelect_optionApp }}
      />
      <FrankieInteractiveCard
        onClick={handleSelect(MfaTypes.Email)}
        title={t('mfaTypeSelect.options.email.name')}
        helperText={t('mfaTypeSelect.options.email.descr')}
        startIcon={{ name: 'mdiEmailOutline', size: 'lg' }}
        testId={{ card: mfaQa.mfaTypeSelect_optionEmail }}
      />
    </div>
  )
}
