import React from 'react'

import { ReactSVG } from 'react-svg'

import spinner from './asset/spinner.svg'
import { ContainerStyle, FrankieLoaderStyle } from './loader.theme'
import { IFrankieLoaderProps } from './loader.types'

export function FrankieLoader({
  loading,
  label,
  children,
  className,
  fullscreen = false,
  size = 'sm',
  testId = {
    loader: 'frankie-loader-loader',
  },
}: IFrankieLoaderProps) {
  if (fullscreen && !loading) {
    return null
  }

  return (
    <div className={ContainerStyle({ fullscreen, className })}>
      {loading && (
        <div className="absolute z-10 inset-0 flex flex-col flex-initial justify-center items-center bg-mono-white bg-opacity-90">
          <div data-qa={testId.loader} className={FrankieLoaderStyle({ size })}>
            <ReactSVG src={spinner} />
          </div>
          {label}
        </div>
      )}
      {children}
    </div>
  )
}
