import { cva } from 'cva'

export const FrankieTextFieldStyle = cva(
  [
    'w-full',
    'rounded-sm',
    'border',
    'border-solid',
    'box-border',
    'px-4',
    'bg-mono-white',
    'border-tertiary-grey-300',
    'text-mono-90',
    'text-sm',
    'placeholder:text-mono-60',
    'focus-visible:border-primary-800',
    'focus-visible:outline-0',
    'disabled:border-tertiary-grey-300',
    'disabled:bg-tertiary-grey-50',
    'disabled:text-tertiary-grey-400',
  ],
  {
    variants: {
      size: {
        sm: ['h-9', 'py-2'],
        md: ['h-10', 'py-3'],
        lg: ['h-13', 'py-3'],
      },
      inputError: {
        true: ['!border-tertiary-red-700', '!text-tertiary-red-700'],
      },
      startIcon: {
        true: ['pl-[42px]'],
      },
    },
  },
)
