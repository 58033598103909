import { ActionGroupTypes } from '../../../model/action-group.enum'
import { ActionGroupId, IPermission } from '../../../model/role.model'

type PermissionCheck = IPermission & { checked: boolean }

export type PermissionGroup = {
  id: ActionGroupId
  name: string
  checks: PermissionCheck[]
  checked: boolean
  partial: boolean
}

const actionGroupColumns: ActionGroupTypes[][] = [
  [
    ActionGroupTypes.Onboarding,
    ActionGroupTypes.Monitoring,
    ActionGroupTypes.NewProfile,
    ActionGroupTypes.Entity,
    ActionGroupTypes.OcrBiometry,
  ],
  [
    ActionGroupTypes.ManualIdv,
    ActionGroupTypes.Risk,
    ActionGroupTypes.Audit,
    ActionGroupTypes.IdvDocument,
    ActionGroupTypes.DuplicateBlacklist,
    ActionGroupTypes.DuplicateBlocklist,
    ActionGroupTypes.Reporting,
  ],
  [
    ActionGroupTypes.Display,
    ActionGroupTypes.ParentChild,
    ActionGroupTypes.Organisation,
    ActionGroupTypes.RoleManagement,
    ActionGroupTypes.UserManagement,
    ActionGroupTypes.Sso,
  ],
]

export function groupPermissions(
  options: IPermission[],
  values: IPermission[],
): PermissionGroup[][] {
  const permissionChecksMap: Map<ActionGroupTypes, PermissionCheck[]> = new Map<
    ActionGroupTypes,
    PermissionCheck[]
  >()

  // group permissions by actionGroupType
  options.forEach(option => {
    const checked = !!values.find(value => value.id === option.id)
    const checksGroup = permissionChecksMap.get(option.actionGroup.alias)
    if (checksGroup) {
      checksGroup.push({ ...option, checked })
    } else {
      permissionChecksMap.set(option.actionGroup.alias, [
        { ...option, checked },
      ])
    }
  })

  // map static columns of actionGroupTypes to real actionGroups
  return actionGroupColumns.map(column =>
    column
      .map<PermissionGroup | undefined>(groupType => {
        const checksGroup = permissionChecksMap.get(groupType)
        if (!checksGroup) {
          return checksGroup
        }
        const checkedCount = checksGroup.reduce(
          (count, check) => count + +check.checked,
          0,
        )
        return {
          checks: checksGroup,
          checked: checkedCount > 0,
          partial: checkedCount < checksGroup.length,
          id: checksGroup[0].actionGroup.id,
          name: checksGroup[0].actionGroup.name,
        }
      })
      .filter<PermissionGroup>((group): group is PermissionGroup => !!group),
  )
}
