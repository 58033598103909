import React from 'react'

import { FrankieButton } from 'frankify/src'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'

import { ACCOUNT_LOCKED_KEY } from '../../account-locked.key'
import { accountLockedQa } from '../../qa/account-locked.qa'

type Props = {
  canUnlock?: boolean
}
export function AccountLocked({ canUnlock = false }: Props) {
  const [t] = useI18n([ACCOUNT_LOCKED_KEY])

  return (
    <div className="flex flex-initial flex-col gap-2">
      <div
        data-qa={accountLockedQa.header}
        className="text-primary-700 text-3xl leading-tight font-bold"
      >
        {t('heading')}
      </div>
      {canUnlock ? (
        <div>
          <div
            data-qa={accountLockedQa.errorMessage}
            className="text-tertiary-grey-700 text-md mb-6"
          >
            {t('contactFrankieOne')}
          </div>
          <a href={externalLinks.support} rel="noreferrer" target="_blank">
            <FrankieButton testId={{ button: accountLockedQa.contactUsCta }}>
              {t('contactUs')}
            </FrankieButton>
          </a>
        </div>
      ) : (
        <div
          data-qa={accountLockedQa.errorMessage}
          className="text-tertiary-grey-700 text-md"
        >
          {t('contactAdmin')}
        </div>
      )}
    </div>
  )
}
