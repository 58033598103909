import { ACCOUNT_LOCKED_KEY, accountLockedEn } from 'features/account-locked'
import { LOGIN_KEY, loginEn } from 'features/login'
import { MFA_KEY, mfaEn } from 'features/multi-factor-auth'
import { NEW_PASSWORD_LOCALE, newPasswordEn } from 'features/new-password'
import {
  ORGANISATION_SETTINGS_KEY,
  organisationSettingsEn,
} from 'features/organisation-settings'
import { roleManagementEn, ROLE_MANAGEMENT_KEY } from 'features/role-management'
import { userManagementEn, USER_MANAGEMENT_KEY } from 'features/user-management'

export const features = {
  [NEW_PASSWORD_LOCALE]: newPasswordEn,
  [ROLE_MANAGEMENT_KEY]: roleManagementEn,
  [USER_MANAGEMENT_KEY]: userManagementEn,
  [LOGIN_KEY]: loginEn,
  [ACCOUNT_LOCKED_KEY]: accountLockedEn,
  [ORGANISATION_SETTINGS_KEY]: organisationSettingsEn,
  [MFA_KEY]: mfaEn,
}
