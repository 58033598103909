import React from 'react'

import { FrankieBadgeStyle } from './badge.theme'
import { IFrankieBadgeProps } from './badge.types'

/**
 * [Figma Badge]{@link https://www.figma.com/file/anPnGr7dr0GvGzQiwzENV4/FrankieOne-Master-DS?node-id=3335%3A17087&mode=dev}
 */
export function FrankieBadge({
  className = '',
  theme,
  text,
  size = 'sm',
  testId = {
    badge: '',
  },
}: IFrankieBadgeProps): JSX.Element {
  return (
    <span
      className={FrankieBadgeStyle({
        theme,
        size,
        className,
      })}
      data-qa={testId.badge}
    >
      {text}
    </span>
  )
}
