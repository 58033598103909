import React from 'react'

import './tooltip.css'
import { FrankieTooltipStyle } from './tooltip.theme'
import { IFrankieTooltipProps } from './tooltip.types'

export function FrankieTooltip({
  title,
  body,
  children,
  className,
  position,
  testId = {
    tooltip: '',
  },
}: IFrankieTooltipProps) {
  return (
    <div className="frankie-tooltip-container relative w-fit">
      <div
        data-qa={testId.tooltip}
        className={FrankieTooltipStyle({ className, position })}
      >
        {title && (
          <div className="mb-1.5 text-mono-white leading-none text-sm font-medium">
            {title}
          </div>
        )}
        {body && (
          <div className="text-tertiary-grey-400 leading-tight text-xs">
            {body}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}
