import React, { ReactElement, useEffect } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { resetPasswordApi, ResetPasswordSentQa } from 'features/reset-password'

import { useI18n } from 'shared/i18n'
import type { Nullable } from 'shared/typescript'

import { PASSWORD_RESET_SENT_PAGE_LOCALE } from '../locale/reset-password-sent-page.en'

type LocationState = {
  shoudSupressReset?: boolean
}

type Props = {
  loginPath: string
  errorPath: string
}

export function PasswordResetSentPage({
  loginPath,
  errorPath,
}: Props): ReactElement {
  const [t] = useI18n([PASSWORD_RESET_SENT_PAGE_LOCALE])
  const navigate = useNavigate()
  const handleNavToLogin = () => navigate(loginPath)

  const location = useLocation()
  const locationsState = location.state as Nullable<LocationState>

  const { email } = useParams()

  useEffect(() => {
    async function fetchData() {
      if (!locationsState?.shoudSupressReset && email) {
        try {
          await resetPasswordApi.requestResetPasswordLink(email)
        } catch (error) {
          navigate(errorPath)
        }
      }
    }

    void fetchData()
  }, [locationsState?.shoudSupressReset, navigate, email, errorPath])

  return (
    <div id="main-container">
      <span
        className="text-primary-700 text-3xl font-bold"
        data-qa={ResetPasswordSentQa.header}
      >
        {t('passwordResetSentPage:container.headingSent')}
      </span>
      <div className="text-tertiary-grey-700 text-lg font-semibold">
        {t('passwordResetSentPage:container.subheading.success')}
      </div>
      <div className="text-tertiary-grey-700 text-md mt-2">
        {t('passwordResetSentPage:container.description.success')}
      </div>
      <div className="text-tertiary-grey-700 text-md mt-2">
        {t('passwordResetSentPage:container.description.checkSpam')}
      </div>

      <div className="flex flex-initial justify-end mt-6">
        <FrankieButton
          intent="subtle"
          onClick={handleNavToLogin}
          testId={{ button: ResetPasswordSentQa.backToLoginButton }}
        >
          {t('passwordResetPage:form.button.backToLogin.default')}
        </FrankieButton>
      </div>
    </div>
  )
}
