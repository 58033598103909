import {
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlert,
  mdiInformation,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiClose,
  mdiInformationOutline,
  mdiAccountCircleOutline,
  mdiAccountCircle,
  mdiAccount,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiDelete,
  mdiCheck,
  mdiEmailOutline,
  mdiCellphoneKey,
  mdiDotsHorizontal,
  mdiSwapVertical,
  mdiArrowUp,
  mdiArrowDown,
  mdiLockReset,
  mdiMagnify,
  mdiMinus,
  mdiAccountMinusOutline,
} from '@mdi/js'
import { PersonOutline, AccountCircleOutlined } from '@mui/icons-material'

export const MdiIcons = {
  mdiCheck,
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlert,
  mdiInformation,
  mdiClose,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiDelete,
  mdiDotsHorizontal,
  mdiInformationOutline,
  mdiAccountCircleOutline,
  mdiAccountCircle,
  mdiAccount,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiEmailOutline,
  mdiCellphoneKey,
  mdiSwapVertical,
  mdiArrowUp,
  mdiArrowDown,
  mdiLockReset,
  mdiMagnify,
  mdiMinus,
  mdiAccountMinusOutline,
}

export const MuiIcons = {
  PersonOutline,
  AccountCircleOutlined,
}

export type FrankieIconMdiEnum = keyof typeof MdiIcons
export type FrankieIconMuiEnum = keyof typeof MuiIcons

export type FrankieIconLibrary = 'mdi' | 'mui'
export type FrankieIconName = FrankieIconMdiEnum | FrankieIconMuiEnum

const FRANKIE_ICON_UNION_SIZES = ['2xs', 'xs', 'sm', 'md', 'lg', 'xl'] as const
type typedFrankieIconUnionSizesList = typeof FRANKIE_ICON_UNION_SIZES
export type FrankieIconUnionSize = typedFrankieIconUnionSizesList[number] // this compiles to 'xs' | 'sm' | ...

export function isFrankieIconUnionSize(
  value: string,
): value is FrankieIconUnionSize {
  return FRANKIE_ICON_UNION_SIZES.includes(value as FrankieIconUnionSize)
}

export type FrankieIconSize =
  | FrankieIconUnionSize
  | `${string}rem`
  | `${string}px`

export interface IFrankieIconProps {
  /**
   * Extra CSS classes
   */
  className?: string

  /**
   * the library used to for the given icon
   */
  library?: FrankieIconLibrary

  /**
   * name of the icon that should be rendered
   */
  name: FrankieIconName

  /**
   * the size at which the icon should be rendered, should be same no matter what library you use
   * could be in a union 'xs', 'sm', 'md', 'lg', 'xl' or a css unit
   * 2xs - 12px
   * xs - 16px
   * sm - 20px
   * md - 24px
   * lg - 32px
   * xl - 64px
   */
  size?: FrankieIconSize

  /**
   * Testing identifiers for Jest and Cypress
   */
  testId?: {
    icon?: string
  }
}

export interface IFrankieIconSvgProps {
  className: string
  library: FrankieIconLibrary
  name: FrankieIconName
}

export interface IFrankieIconSizeProps {
  icon: ((props: any) => JSX.Element) | null
  library: FrankieIconLibrary
  size: FrankieIconSize
}
