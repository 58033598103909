import React, { ReactElement } from 'react'

import { Link, useParams } from 'react-router-dom'

import {
  FrankieAlert,
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import {
  usePermissionOptionsQuery,
  useSingleRoleQuery,
} from 'features/role-management'

import { PermissionCheckboxGroup, PermissionTypes, RoleId } from 'entities/role'
import { useHasPermission, useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { settingsRoleViewQa } from '../../qa/settings-role-view.qa'
import { SETTINGS_ROLE_VIEW_PAGE_KEY } from '../../settings-role-view.key'

type Props = {
  roleListPath: string
  roleIdParamKey: string
  getRoleEditPath: (id: RoleId) => { path: string; state: unknown }
}

// eslint-disable-next-line complexity
export function SettingsRoleViewPage({
  roleIdParamKey,
  roleListPath,
  getRoleEditPath,
}: Props): ReactElement {
  const [t] = useI18n([SETTINGS_ROLE_VIEW_PAGE_KEY])
  const params = useParams()
  const roleId = params[roleIdParamKey]
  const { canFetchRole, canFetchPermissions, canEditRole } = useHasPermission({
    canFetchRole: PermissionTypes.RoleDetailFunction,
    canFetchPermissions: PermissionTypes.ActionListFunction,
    canEditRole: PermissionTypes.RoleEditPage,
  })
  const { path: roleEditPath, state: roleEditState } = getRoleEditPath(
    Number(roleId),
  )
  const { data: session } = useSessionQuery()
  const { data: permissionList, isLoading: isPermissionListLoading } =
    usePermissionOptionsQuery({ canFetchPermissions })
  const { data: role, isLoading: isRoleLoading } = useSingleRoleQuery(
    Number(roleId),
    { canFetchRole },
  )
  const isLoading: boolean = isRoleLoading || isPermissionListLoading

  const isChildOrganisationsRole: boolean =
    session?.organisation?.id !== role?.organisationId

  const showEditRoleCta: boolean =
    !role?.isGlobalRole && !isChildOrganisationsRole && canEditRole

  if (!isLoading) {
    return (
      <div className="h-[calc(100vh-92px)] p-8 pr-4 mr-2 overflow-y-auto scrollbar">
        <div className="mb-8 flex w-full flex-initial flex-row justify-between items-center">
          <div className="basis-full shrink min-w-0">
            <Link
              to={roleListPath}
              className="mb-3 text-tertiary-grey-500 text-xs flex leading-[18px]"
              data-qa={settingsRoleViewQa.goBackCta}
            >
              <FrankieIcon size="xs" name="mdiChevronLeft" />
              {t('cta.goBack')}
            </Link>
            <div
              data-qa={settingsRoleViewQa.roleName}
              className="text-2xl font-bold text-tertiary-grey-700 leading-8"
            >
              {role?.role}
            </div>
            {role?.description && (
              <div
                data-qa={settingsRoleViewQa.roleDescription}
                className="mt-2 text-sm max-w-[50%] break-words text-tertiary-grey-700"
              >
                {role.description}
              </div>
            )}
          </div>
          {showEditRoleCta && (
            <Link
              data-qa={settingsRoleViewQa.createRoleCta}
              to={roleEditPath}
              state={roleEditState}
              className="min-w-fit"
            >
              <FrankieButton notAButton size="sm" intent="secondary">
                {t('cta.editRole')}
              </FrankieButton>
            </Link>
          )}
        </div>
        {isChildOrganisationsRole && (
          <FrankieAlert
            type="info"
            className="mb-8 text-tertiary-grey-700"
            title={t('alert.childRole')}
            testId={{ alert: settingsRoleViewQa.childRoleAlert }}
          />
        )}
        <div className="mb-6 text-xl font-bold text-tertiary-grey-700">
          {t('header.permissions')}
        </div>
        {permissionList && role && (
          <div>
            <PermissionCheckboxGroup
              options={permissionList}
              values={role.permissions}
              disabled
            />
          </div>
        )}
      </div>
    )
  }

  return <FrankieLoader className="min-h-screen" loading />
}
