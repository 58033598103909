import { Buffer } from 'buffer'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { MfaTypes } from 'entities/mfa'
import { PageData, SessionQueryKey } from 'entities/session'

import { bffClient } from 'shared/client'
import { StorageKeyTypes, getLocalStorageState } from 'shared/local-storage'

type MutationArguments = {
  email: string
  password: string
  mfaCode?: string
}

type LoginResponse = PageData | LoginResponseError | LoginResponseSuccessMfa

type LoginResponseError = {
  error: string
}

type LoginResponseSuccessMfa = {
  message: string
  mfaType: MfaTypes
}

const MFA_NOT_REQUIRED_RESPONSE_STATUS = 200

export const encodeAuthToBase64 = (email: string, password: string) =>
  Buffer.from(`${email}:${password}`).toString('base64')

export const useLoginMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ email, password, mfaCode }: MutationArguments) => {
      const base64Str = encodeAuthToBase64(email, password)

      return bffClient.post<LoginResponse, { token: string | undefined }>(
        '/auth/v1/sessions',
        { token: mfaCode },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${base64Str}`,
          },
        },
      )
    },
    onSuccess: successfulResponse => {
      if (successfulResponse.status === MFA_NOT_REQUIRED_RESPONSE_STATUS) {
        const responseData = successfulResponse.data as PageData

        const [, setPageData] = getLocalStorageState(StorageKeyTypes.PageData)
        const [, setToken] = getLocalStorageState(StorageKeyTypes.Token)

        queryClient.setQueryData(SessionQueryKey, responseData)

        setPageData(responseData)
        setToken(responseData.token)
        bffClient.setToken(responseData.token.toString())
      }
    },
  })
}
