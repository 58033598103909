import React from 'react'

import { Outlet } from 'react-router-dom'

export function LayoutDefault(): JSX.Element {
  return (
    <div className="">
      <Outlet />
    </div>
  )
}
