import { cva } from 'cva'

export const FrankieBadgeStyle = cva(
  [
    'rounded-1.5',
    'flex',
    'flex-initial',
    'gap-1',
    'items-center',
    'justify-center',
    'w-fit',
  ],
  {
    variants: {
      size: {
        sm: ['h-5', 'py-0.5', 'px-2.5', 'text-xs', 'font-semibold'],
        lg: ['h-7', 'py-1', 'px-3', 'text-sm', 'font-semibold'],
      },
      theme: {
        green: ['text-tertiary-green-600', 'bg-tertiary-green-100'],
        red: ['text-tertiary-red-800', 'bg-tertiary-red-100'],
      },
    },
  },
)
