export enum PermissionTypes {
  // Role Management, actionGroup role_management

  ActionListFunction = 'ffportal_action_list.function',
  RolePermissionMappingFunction = 'ffportal_role_permission_mapping.function',
  RolePermissionMappingPage = 'ffportal_role_permission_mapping.page',
  RoleDeleteFunction = 'ffportal_role_delete.function',
  RoleUpdateFunction = 'ffportal_role_update.function',
  RoleEditPage = 'ffportal_role_edit.page',
  RoleCreateFunction = 'ffportal_role_create.function',
  RoleNewPage = 'ffportal_role_new.page',
  RoleListFunction = 'ffportal_role_list.function',
  RoleIndexPage = 'ffportal_role_index.page',
  RoleDetailFunction = 'ffportal_role_detail.function',

  // User Management, actionGroup user_management

  RoleUserPermissionListPage = 'ffportal_role_user_permission_list.page',
  CreditHeaderAcknowledgement = 'ffportal_credit_header_acknowledgement',
  UserLock = 'ffportal_user_lock',
  UserEdit = 'ffportal_user_edit',
  UserAdd = 'ffportal_user_add',
  UserList = 'ffportal_user_list',
  UserIndex = 'ffportal_user_index',
  UserDetail = 'ffportal_user_detail',
  UserDelete = 'ffportal_user_delete',

  // Organisation settings, actionGroup admin

  OrganisationSettingsFetch = 'ffportal_admin_fetch_settings_data',
  OrganisationSettingsUpdate = 'ffportal_admin_update_settings_data',
}
